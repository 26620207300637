@import '../../../src/styles/colors';

.page {
  background: $color-gray-light;
  justify-content: flex-start;
}

.header {
  padding: 3rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}

.body {
  padding: 0 3rem 3rem 3rem;
}
