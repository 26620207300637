@import '../../../src/styles/variables';

.container {
  background-color: white;
  margin-bottom: 1rem;
  padding: 1rem 4rem 1rem 1rem;
  font-size: 1.3rem;
  line-height: 1.5rem;
  position: relative;
  min-height: 3rem;
  display: flex;

  &::before {
    position:absolute;
    content: " ";
    display:block;
    left:-5px;
    right: 0;
    bottom:0;
    top:0;
    width:10px;
    background-size: 5px 100%;
    background-image: url("../../assets/images/corner.svg");
  }

  &::after {
    position:absolute;
    content: " ";
    display:block;
    right: -3px;
    bottom:0;
    top:0;
    width:10px;
    background-size: 3px 100%;
    background-image: url("../../assets/images/corner.svg");
    transform:scaleX(-1);
  }
  .label {
    flex-grow: 1;
  }
  .arrow {
    width: 1rem;
    flex-shrink: 0;
    margin-left: 0.5rem;
  }

  .score {
    position: absolute;
    right: 0.5rem;
    top: -1rem;
    z-index: 9;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    .star {
      width: 3rem;
      position: absolute;
    }
    span {
      position: absolute;
      font-weight: bold;
    }
  }

  &.empty {
    opacity: 0.6;
  }

  &.clickable {
    cursor: pointer;
  }
}

