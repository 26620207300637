h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

h1 {
  font-size: 2.2rem;
  line-height: 2.5rem;
  margin-bottom: 1.5rem;
  font-weight: 900;
  color: $color-text;
}

h2 {
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin-bottom: 1.5rem;
  color: $color-text;
  font-weight: 500;
}

h3 {
  font-size: 1.6rem;
  line-height: 1.5;
  margin-bottom: 0;
}

p,a {
  margin-bottom: 1rem;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
}

ul li {
  font-family: Arial, Helvetica, sans-serif;
}

button,
a.button
{
  font-size: 1.6rem;
  line-height: 1.5rem;
  font-weight: 700;
}

