@import '../../../src/styles/variables';
@import '../../../src/styles/colors';

.button {
  border: 0 solid transparent;
  background-color: $color-red;
  color: $color-white;
  min-width: $button-size;
  min-height: $button-size;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 200ms;
  justify-content: center;
  text-decoration: none;

  .buttonContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all 200ms;
  }


  &:disabled,
  &.active {
    cursor: default;
    opacity: 0.6;
  }

  &:hover,
  &.active {
    .buttonContent {
      opacity: .5;
    }
  }

  &.small {
    font-size: 1.4rem;
    min-width: $button-size-small;
    min-height: $button-size-small;
    padding: .3rem .7rem;
  }

  &.rounded {
    border-radius: $default-border-radius;
  }

  &.hasBorder {
    border: .3rem solid $color-white;

    &.small {
      border-radius: $small-border-radius;
      border-width: .2rem;
    }
  }


  &.fullWidth {
    width: 100%;
  }
}
