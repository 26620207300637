@import '../../../src/styles/colors';

$scroll-item-height: 62px;

.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  min-height: 22rem;
  position: relative;
  .infoButton {
    position: absolute;
    top: 2rem;
    left: 2rem;
    width: 3rem;
    cursor: pointer;
    z-index: 9999;
  }
  .zoomButton {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    width: 4rem;
    cursor: pointer;
    z-index: 9999;
  }
  .characterContainer {
    height: 100%;
    cursor: pointer;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 10rem;
      top: 0;
      height: 100%;
      background: linear-gradient(90deg, rgba(39, 60, 78, 0) 5%, #273c4e 95%);
      right: 0;
      display: block;
      pointer-events: none;
    }
  }
  .scoreContainer {
    height: 100%;
    display: flex;
    padding: 2rem 0 1rem 0;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    width: 20rem;
    right: 0;
    h1 {
      color: $color-white;
      font-weight: 600;
    }

    .cta {
      background: $color-white;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0.8rem 1.5rem 0.8rem 1rem;
      color: $color-red;
      font-size: 1.6rem;
      font-weight: 600;
      margin: 2rem 0 1rem 0;
      position:relative;
      cursor: pointer;

      &::before {
        position:absolute;
        content: " ";
        display:block;
        left:-10px;
        right: 0;
        bottom:0;
        top:0;
        width:10px;
        background-size: 10px 100%;
        background-image: url("../../assets/images/corner.svg");
      }

      span {
        flex-grow: 1;
      }
      img {
        width: 1rem;
        flex-shrink: 0;
        margin-left: 0.5rem;
      }

      &.disabled {
        opacity: 0.5;
        cursor: default;
      }
    }
  }
  flex: 1;
}
p.instructions {
  background: $color-white;
  width: 100%;
  margin: 0;
  text-align: center;
  padding: 1rem 5rem;
  flex-shrink: 0;
  display: block;
}
.body {
  flex-shrink: 0;
  background: $color-white;
  width: 100%;
  height: $scroll-item-height*3;
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: $scroll-item-height;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%);
    pointer-events: none;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: $scroll-item-height;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%);
    pointer-events: none;
  }

  .scrollContainer {
    padding: $scroll-item-height+10 0 $scroll-item-height+10 0;
    scroll-snap-type: y mandatory;
    width: 100%;
    overflow: auto;
    height: 100%;

    .skillItem {
      padding: 1rem 2rem;
      text-align: center;
      border: 2px solid $color-white;
      margin: 1.5rem 2rem ;
      border-radius: 0.3rem;
      transition: opacity 0.2s ease-in;
      cursor: pointer;
      box-shadow: 0 1px 3px 1px rgba(35, 52, 68, 0.20);
      scroll-snap-align: center;
      opacity: 1;
      font-weight: bold;
      &.outOfFocus {
        opacity: 0.2;
      }

      &.selected {
        border: 2px solid $color-yellow;
      }

      &.disabled {
        background-color: #f7f7f7;
        color: #b1b1b1;
      }
    }
  }

}

.footer {
  padding: 1rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: row;
  background: $color-white;
  width: 100%;
  flex-shrink: 0;

  .footerColumn {
    flex-grow: 1;
    margin: 0 0.4rem;
    flex-basis: 50%;
  }
}

.skillContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .skillItem {
    margin: 2rem;
    cursor: pointer;

  }
}

.popUp {
  &.roomPopUp{
    background-color: $color-dark-blue !important;
  }
  .popUpHeader {
    padding: 3rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
  }

  .popUpBody {
    padding: 0 3rem 3rem 3rem;

    p {
      white-space: pre-wrap;
    }
  }
}
