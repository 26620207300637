@import '../../../src/styles/colors';

.container {
  position: absolute;
  top: 0;
  left:0;
  right: 0;
  bottom: 0;
  background: transparent;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;

  .component {
    display: flex;
    align-content: center;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    max-width: 50rem;
    background: $color-gray-light;
    height: 100%;
    z-index: 9999;
    overflow-y: auto;
  }
}

