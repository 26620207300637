@import "colors";

body,
html,
#root {
  height: 100%;
  width: 100%;
}

html {
  font-size: 10px;
}
