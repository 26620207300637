$button-size: 4.2rem;
$button-size-small: 2.6rem;

$icon-button-size: $button-size;
$icon-button-padding: 0.6rem;

$small-border-radius: .4rem;
$default-border-radius: .6rem;
$large-border-radius: 2rem;

$default-font-size:1.2rem;
$default-line-height: 1.5rem;
$default-font-family: Arial, Helvetica, sans-serif;

$spacer: 1rem;
