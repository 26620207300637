@import '../../../src/styles/colors';

.container {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: $color-blue;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    width: 2rem;
    height: 2rem;
  }

}
