@import '../../../src/styles/colors';

.container {
  .cta {
    background: $color-red;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding:2rem;
    color: $color-white;
    font-size: 1.6rem;
    font-weight: 600;
    position:relative;
    cursor: pointer;

    span {
      flex-grow: 1;
    }
    img {
      width: 2rem;
      flex-shrink: 0;
      cursor: pointer;
    }
  }
  .checkScoresPanel{
    background: #f2f2f2;
    padding:1rem;
    .scoreRow {
      display: flex;
      flex-direction: row;
      padding: 0.2rem 1rem 0.2rem 1rem;

      &.selected {
        padding: 1rem;
        background-color: $color-white;
        border: 2px solid $color-yellow;
      }
      margin: 0 0 1rem 0;
      span {
        flex-grow: 1;
      }

      .stars {
        display: flex;
        flex-direction: row;

        .star {
          width: 1.5rem;
          margin-right: 0.2rem;

          img {
            opacity: 0.4;
          }

          img.selected {
            opacity: 1;
          }
        }
      }
    }
  }

  .spacer {
    margin-top: 3rem;
    white-space: pre-wrap;
  }

  a {
    color:$color-dark-blue;
  }

}

