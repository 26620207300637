
@import '../../../src/styles/colors';

.component {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 50rem;
  background: $color-dark-blue;
  height: 100%;
  flex-wrap: nowrap;
  overflow-y: auto;
  position: relative;
}

