.logo {
  width: 100%;
  padding: 4rem 2rem 0 2rem;
  position: absolute;
  z-index: 999;
}
.character {
  flex-grow: 1;
  width: 100%;
  max-height: 60rem;
}
.footer {
  width: 100%;
  height: 15rem;
  padding: 2rem;
  background: rgb(0,133,185);
  background: linear-gradient(177deg, rgba(0,133,185,0.4) 0%, rgba(35,52,68,1) 100%);
  text-align: center;
  h1 {
    margin: 0;
  }
  h1, h2 {
    color: #FFFFFF;
  }
}

